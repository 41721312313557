 
@font-face {
  font-family: 'swiss_1';
  src: url('./swis721/swiss_1.ttf'); 
}
 
@font-face {
  font-family: 'swisscbi_1';
  src: url('./swis721/swisscbi_1.ttf');
}
 
html{
  min-height: 100%;
  font-family: 'swiss_1'; 
}
 
body {
  /* margin: 0;
  padding: 0;
  /* background-color: #95e8f3; */
  /* min-height: 100%; */
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
.userBody {
  background-color: #333;
  font-family: 'Poppins', sans-serif;
  padding-bottom: 50px;
  min-height: 100vh;
}
.userBody .userBody {
  min-height: auto;
}
.adminBody {
  background-color: #fff;
}
 
form{
  padding: 15px;
  /* margin-top: 30px; */
} 
 
form button, form h5{
  margin: 20px 0;
}
 
input[type=text]:not(.browser-default):focus:not([readonly]),
input[type=email]:not(.browser-default):focus:not([readonly]),
input[type=password]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]){
  border-color: #035278;
  box-shadow: none;
}
 
input[type=text]:not(.browser-default):focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label,
input[type=email]:not(.browser-default):focus:not([readonly]) + label,
input[type=password]:not(.browser-default):focus:not([readonly]) + label{
  color: #035278 !important;
}
 
/* login */
 
.ReactModal__Content{
  left: 300px !important;
  right: 300px !important;
  bottom: auto !important;
}
.loginContainer {
  background-color: #f4f7f9;
  padding-top: 5%;
  height: 100vh;
}
.card.hoverable {
  margin-left: auto;
  margin-right: auto;
}
.card.hoverable {
  margin-left: auto;
  margin-right: auto;
  background-color: #E9EEF2;
  width: 35em;
  box-shadow: none;
  -webkit-box-shadow: none;
}
 
.ReactModal__Overlay.ReactModal__Overlay--after-open{
  z-index: 12;
 
}
 
.brand_logo_container {
  height: 100px;
}
.brand_logo {
  height: 80%;
}
 
.btn-login {
  background-color: #035278 !important;
  color: #FFFFFF;
  padding: 0px 60px;
  margin-top: 15px;
}
 
.modal_trigger{
  cursor: pointer;
}
/* 
.modal{
  top:10px;
  width: 50%;
} */
 
 
.card-title{
  color: #4F4F5E;
  font-family: swiss_1;
}
 
.recoverPass{
  margin-bottom: 2em;
  margin-top: -30px;
}
 
.reset_password{
  font-size: 12px;
  font-family: swisscbi_1;
  color: #035278;
}
 
.icon_user, .icon_pass{
  color: #035278 !important;
}
 
.title_access{
  font-size: 20px;
  color:  #4F4F5E;
}
 
.loginAccess input {
  background-color: #FFFFFF !important;
  border: 1px solid #FFFFFF !important;
  border-radius: 5px !important;
  padding: 0px 15px 0px 50px !important;
  font-size: 16px !important;
  width: 70% !important;
}
.loginAccess .input-field .material-icons.prefix {
  position: absolute;
  top: .5rem;
  left: 3.5rem;
}
.loginAccess .input-field .material-icons.prefix.active {
  opacity: 0.6;
  color: #035278;
}
.loginAccess .input-field.icon_user {
  margin-top: 35px;
}
.input-field .prefix ~ label {
  margin-left: 6rem !important;
}
.input-field .prefix ~ label.active {
  margin-left: 3.5rem !important;
}
 
.color_recover{
  color: #1976d2;
}
 
.ReactModal__Content.ReactModal__Content--after-open {
  background-color: #f4f7f9 !important;
  border: 0 !important;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
  -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
}
 
.ReactModalPortal .input-field input {
  background-color: #FFFFFF;
  border: 1px solid #FFFFFF;
  padding-left: 15px;
  width: 98%;
  border-radius: 5px;
  margin-top: 15px;
}
.ReactModalPortal .input-field label {
  top: 1em;
  left: 15px;
}
.ReactModalPortal .btn-login {
  margin-top: 9px;
  margin-bottom: 15px;
}
 
/* footer */
 
.page-footer {
  background-color: #212121;
  color: #ffffff;
  padding: 0 125px;
}
 
.page-footer .footer-copyright{
  color:  #FFFFFF;
  font-family: swiss_1;    
  font-size: 14px;
}
 
.footer-fixed {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 2;
}
 
/*footer.page-footer.footer-fixed {
  position: relative !important;
}*/
 
.adminBody .page-footer {
  background-color: #E9EEF2;
  color: #333;
}
 
.adminBody .page-footer .footer-copyright{
  color:  #333;
  background-color: #E9EEF2;
}
 
/* navbar */
 
.userBody nav{
  background-color: #FFFFFF;
    position: relative;
    top: 0;
    z-index: 3;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-bottom: 1px solid #eeeeee;
    padding: 0px 0 25px;
    height: 93px !important;
}
.userBody nav .brand-logo {
 /* position: absolute;
  color: #fff;
  display: inline-block;
  font-size: 2.1rem;
  padding: 0;
  top: 0px;*/
  left: 9.3333333333%;
 /* max-width: 100%;
  width: 22%;*/
}
.userBody nav .brand-logo .brand-logos.log_nav {
  width: 10rem;
}
.userBody .nav-wrapper .right .color_links.active {
  background-color: transparent;
  border-bottom: 3px solid #888;
  padding: 0;
  color: #888;
  font-weight: 600;
  margin-right: 15px;
}
.userBody .nav-wrapper .right .color_links:hover {
  background-color: transparent;
  border-bottom: 3px solid #888;
  padding: 0;
  color: #888;
  font-weight: 600;
}
.userBody .nav-wrapper .right .color_links {
  padding: 0;
  color: #565555;
  font-weight: 600;
  display: inline-block;
  margin-right: 15px;
}
 
.userBody .nav-wrapper ul .optionOff .logout:hover{
  border-bottom: 0;
  color: red;
  background-color: transparent;
}
.userBody .nav-wrapper ul .optionOff:hover i{
  color: red;
}
.userBody .nav-wrapper .right {
  margin-right: 6.5%;
}
.userBody .nav-wrapper .right li {
  height: 35px !important;
  line-height: 38px !important;
  margin-right: 10px;
}
.userBody .nav-wrapper .right li i {
  height: 30px;
    line-height: 36px;
    margin-right: 9px;
    font-size: 20px;
    vertical-align: sub;
}
 
/*.userBody .nav-wrapper ul li a:hover{
  background-color: transparent;
  border-bottom: 3px solid #888;
}*/
 
.adminBody nav.nav-wrapper {
  background-color: #e9eef2;
  height: 85px;
}
.adminBody img.brand-logo.log_nav {
  width: 10%;
  top: -14px;
  left: 120px;
}
.adminBody nav.nav-wrapper .right {
  padding-top: 16px;
  margin-right: -100px;
}
 
 
/* lista clientes */
 
.clients{
  font-size: 1.5rem;
  font-weight: 600;
  color: #666;
  display: inline-block;
}
.add-icon .fa-city,
.add-icon .fa-building,
.add-icon .fa-file,
.add-icon .fa-user-circle {
  font-size: 1.5rem;
  color: #035278;
  margin-right: 15px;
}
 
.div_ul{
  width: 80%;
}
 
ul{
  list-style-type: none;
  padding: 0;
}
 
.li-list {
  padding: 14px;
  background: #f6f6f6;
  font-size: 18px;
  color: #555;
  position: relative;
  border: 1px solid #e6e6e6;
  height: 2.5em;
  line-height: 6px;
}
 
.li-list:nth-child(even){
  padding: 14px;
  background: #f2f2f2;
}
 
.li-list h5{
  padding-left: 0;
  color: #4F4F5E;
  font-size: 1em;
}
 
.client_list_class{
  display: inline;
}
 
.plus_client, .admin-button{
  background-color: #035278 !important;
  font-weight: 600;
}
 
.btn-floating.btn-large {
  width: 40px;
  height: 40px;
  padding: 0;
  margin-top: 15px;
}
 
.btn-floating.btn-large i {
  line-height: 43px;
}
.plus_client:hover {
  background-color: #E9EEF2 !important;
  border: 2px solid #035278;
  color: #035278;
}
.plus_client:hover i {
  color: #035278;
  line-height: 1.5 !important;
  vertical-align: super;
  margin-left: -1px;
}
 
.add-icon{
  margin-top: 4rem;
  margin-bottom: 6rem;
  position: relative;
}
.col.add-icon {
  margin-top: 4rem;
  margin-bottom: 2rem;
}
 
.dashboard{
  margin-bottom: 6em;
}
 
.edit_class{
  position: absolute;
  top: 0;
  right: 40px !important;
  background: transparent;
  width: 2.5em;
  height: 2.4em;
  text-align: center;
  /* padding: 12px 0; */
  font-weight: bold;
  cursor: pointer;
  border-left: 2px solid #dfdfdf;
}
 
.edit_class .material-icons{
  line-height: 2.2 !important;
  color: #035278;
  font-size: 1.1em;
}
 
.edit_class .material-icons:hover{
  color:#0b93d4;
}
 
.delete_class .material-icons{
  line-height: 2.2 !important;
  color: #035278;
  font-size: 1.1em;
}
 
.delete_class .material-icons:hover{
  color:#0b93d4;
}
 
 
 
.li-list .div_delete{
  position: absolute;
    top: 0;
    right: 0px;
    background: transparent;
    width: 2.4em;
    height: 2.4em;
    text-align: center;
    /* padding: 12px 0; */
    font-weight: bold;
    cursor: pointer;
    border-left: 2px solid #dfdfdf;
}
 
.logo_client{
  width: 15rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
 
.btn.waves-effect.waves-light.project_button i {
  font-size: 1.2rem;
}
.btn.waves-effect.waves-light.users_button i.left,
.btn.waves-effect.waves-light.project_button i.left {
  margin-right: 0;
}
 
.title-projects{
  margin-top: 7rem;
}
 
.project_button, .users_button{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  background-color:  #035278;
}
 
.project_button:hover, .users_button:hover, .btn-login:hover{
  /*background-color:  #e3f2fd !important;
  color: #4F4F5E;*/
  opacity: 0.5;
  background-color: #035278;
}
 
.white select.browser-default {
  max-width: 97%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 25px;
  background-color: #f4f7f9;
  color: #888;
}
 
.client-list .li-list a:first-child:hover h5 {
  opacity: 0.5;
}
 
.container-preview{
  margin-top: -1rem;
  margin-bottom: 6rem;
}
 
/*.logout{
  color: #4F4F5E !important;
}*/
.adminBody .logout {
  color: #4F4F5E !important;
  line-height: 2.5;
  margin-top: 15px;
  padding: 0;
  margin-right: 15px;
  text-transform: uppercase;
  font-weight: 600;
}
.adminBody .logout:hover {
  background-color: transparent;
  border-bottom: 3px solid #4F4F5E;
}
 
.white h5 {
  font-size: 1.3rem;
  font-weight: 600;
  color: #666;
  display: inline-block;
  text-transform: uppercase;
}
.white .input-field {
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
}
 
.section .fa-city {
  font-size: 1.5rem;
  color: #035278;
  margin-right: 15px;
}
 
.adminBody h4.title-projects.clients {
  font-size: 1.4em;
}
 
h5.title-projects.titleProyAsign {
  margin-top: 2rem;
  font-size: 1.3rem;
  font-weight: 600;
  color: #666;
  display: inline-block;
  text-transform: uppercase;
  margin-bottom: 1.5em;
}
 
.white .file-field .btn.btn-login {
  width: 100%;
  padding: 3px;
  line-height: 29px;
  height: 33px;
  margin-right: auto;
  margin-left: auto;
}
 
.white .file-path-wrapper {
  padding-left: 1px;
  position: relative;
}
.white .file-path-wrapper .file-path.validate {
  padding-left: 170px;
  border-bottom: 1px solid;
}
.white .file-path-wrapper span {
  position: absolute;
  top: 13px;
  color: #888;
  font-size: 1em;
}
 
.react-datepicker-wrapper {
  width: 100%;
}
 
.white input::placeholder {
  color: #888;
}
 
.white .input-field label {
  width: 97% !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
 
.boxPreviewImg {
  margin: 50px 30px;
  padding: 15px 15px 25px;
  border: 2px solid #dfdfdf;
  text-align: center;
}
 
 
 
.boxPreviewImg h6 {
  color: #888;
  font-size: 1em;
  text-transform: capitalize;
  text-align: left;
}
 
@media (max-width: 992px){
  nav .brand-logo {
    left: 50% !important;
  }
}
 
[type="checkbox"].filled-in:checked+span:not(.lever):after {
 
  background-color: #035278 !important;
  border:2px solid #035278 !important;
}
 
/* users styles */
 
 
 
/*list of proyects*/
 
.boxImgClient {
  position: relative;
  margin-bottom: 17px;
}
.titleBody {
  margin-top: 50px;
}
img.proyectImg {
  width: 100%;
  height: 210px;
}
.textTitleBox i {
  color: #888; 
  font-size: 23px;
  margin-left: 22px;
}
.secondTextMargin i{
  margin-left: 0;
}
.textTitleBox h4 {
  color: #FFFFFF;
  font-size: 22px;
  display: inline-block;
  margin-left: 15px;
  font-weight: 500;
  margin-bottom: 50px;
}
 
.containerImg:hover .capaBoxImgClient {
  width: 100%;
  height: 98%;
  background-color: #888;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
}
.capaBoxImgClient {
  display: none;
}
.containerImg:hover .textImgClient {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #FFFFFF;
    text-align: center;
    width: 90%;
    background-color: #888;
    padding: 11px 0;
    opacity: 0.9;
    display: block;
}

/* .containerImg:hover .textImgClient {
  position: absolute;
    top: 31%;
    left: 17px;
    color: #FFFFFF;
    text-align: center;
    width: 90%;
    background-color: #888;
    padding: 11px 0;
    opacity: 0.9;
    display: block;
} */
 
.textImgClient {
  display: none;
}
 
.textImgClient h5 {
  font-weight: 600;
  margin: 0;
  font-size: 20px;
}
.textImgClient p {
  margin: 5px 0 0;
  font-size: 13px;
}
 
.btn.btnClient {
  margin: 50px 0 50px 12px;
  background-color: #888;
  padding: 0 40px;
}
 
.btn.btnClient:hover {
  color: #888;
  border: 1px solid #888;
  background-color: #fff;
}
 
.boxBtn {
  text-align: center;
}
 
.margen-provisorio{
  margin-top: 15em;
}
 
/*detail*/
.ImgPage {
  margin-top: -14px;
  height: 540px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.capaImgPage {
  background-color: #888;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
}
.boxTextImgPage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* position: absolute;
    top: 32%;
    left: 29%; */
    width: 550px;
    height: 200px;
    text-align: center;
    background-color: #888;
    opacity: 0.9;
    color: #FFFFFF;
    padding: 60px 0;
}
.boxTextImgPage h3 {
  font-weight: 600;
  margin: 0;
}
.boxTextImgPage h6 {
  margin: 5px 0 0;
}
.boxDatePicker {
  margin: 50px 0;
  /* margin-bottom: 150px; */
}
.boxDatePicker .MuiFormLabel-root {
  color: #abaaaa;
  font-size: 18px;
}
.boxDatePicker .MuiFormLabel-root.Mui-focused{
  color: #fff !important;
}
.boxDatePicker .MuiInputBase-root {
  color: #FFF;
  background-color: #666;
}
.boxDatePicker .MuiInput-underline:after {
  border-bottom: 0 !important;
}
.boxDatePicker .MuiInput-underline:before {
  transition: none;
  border-bottom: 0 !important;
}
.boxDatePicker input.MuiInputBase-input.MuiInput-input {
  border-bottom: 0 !important;
  margin-bottom: 0;
  text-align: center;
  text-transform: uppercase;
}
.MuiFormControl-root {
  min-width: 100% !important;
}
.mensajeNo {
  padding: 100px;
  color: #ccc;
  text-align: center;
  text-transform: uppercase;
}
 
label + .MuiInput-formControl {
  margin-top: 29px !important;
}
.MuiPickersToolbar-toolbar {
  background-color: #888 !important;
}
.MuiTypography-colorPrimary {
  color: #888 !important;
}
.MuiButton-textPrimary {
  color: #888 !important;
}
 
.boxTableReports {
  margin: 50px 0 50px 0;
}
.boxTableReports table, 
.boxTableReports table tr,
.boxTableReports table th,
.boxTableReports table td {
  border-radius: 0 !important;
  text-align: center;
}
.boxTableReports .MuiPaper-rounded {
  border-radius: 1px;
}
.boxTableReports table thead {
  background-color: #ccc;
}
.boxTableReports table thead tr th {
  font-size: 15px;
}
.boxTableReports table thead tr th {
  font-size: 14px;
  text-transform: uppercase;
  color: #333;
}
.boxTableReports table tbody {
  background-color: #efefef;
}
.boxTableReports tbody tr th,
.boxTableReports tbody tr td {
  color: #666 !important;
}
.boxTableReports table tbody tr td a i {
  color: #666;
  font-size: 16px;
}
.boxTableReports table tbody tr td a:hover i {
  color: #333;
}
 
/*slider */
.firstSlide .imagenCarousel {
  width: 100%;
  height: 520px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.secondSlide .imagenCarousel {
  max-width: 90%;
  height: 70px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
 
.slick-slider.slick-initialized.secondSlide  {
  margin-bottom: 115px !important;
  margin-top: 10px;
}
p.SubTitleBody {
  color: #ccc;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: 600;
  padding-bottom: 20px;
  text-align: center;
}
.boxSectionBody {
  margin-bottom: 90px;
  margin-top: 0;
}
.video-react-controls-enabled.video-react-paused.video-react-fluid.video-react-user-inactive.video-react-workinghover.video-react {
  max-width: 100% !important;
  padding: 0 !important;
  position: relative;
  padding-top: 0 !important;
}
video.video-react-video {
  max-width: 100%;
}
 
/*date*/
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary {
  background-color: #333;
  color: #dfdfdf !important;
  padding: 5px 35px;
}
h4.MuiTypography-root.MuiPickersToolbarText-toolbarTxt.MuiPickersToolbarText-toolbarBtnSelected.MuiTypography-h4 {
  text-transform: uppercase;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary:hover {
  opacity: 0.6 !important;
}
input#url3D, input#urlVideo {
  text-transform: lowercase;
}
.white .input-field label {
  text-transform: lowercase;
}
.white div:first-child label {
  text-transform: uppercase;
}
footer button.MuiButtonBase-root.MuiButton-root.MuiButton-text {
  position: absolute;
  bottom: 45px;
  right: 45px;
}
footer .contactButton .MuiIcon-root {
  font-size: 1.7rem;
}
footer .contactButton.MuiFab-root{
  width: 47px;
  height: 47px;
}
footer .contactButton.MuiFab-root:focus{
  background-color: #fff !important;
}
footer .MuiButton-root:hover {
  background-color: transparent;
}
.contactModal .MuiDialogTitle-root {
  flex: 0 0 auto;
  margin: 0;
  padding: 16px 24px;
  text-align: right;
  margin-top: 40px;
  text-transform: uppercase;
}
.contactModal .MuiDialogContent-root {
  max-width: 300px;
  margin-left: auto;
  padding-top: 0px;
  max-height: 75px;
}
button.MuiButtonBase-root.MuiFab-root.contactButton:hover {
  opacity: 0.5;
}
h4.MuiTypography-root.MuiPickersToolbarText-toolbarTxt.MuiTypography-h4 {
  text-transform: uppercase ;
}
.contactModal .MuiBackdrop-root {
  background-color: transparent !important;
}
.contactModal .MuiDialog-container.MuiDialog-scrollPaper {
  height: auto;
  position: absolute;
  bottom: -30px;
  right: -15px;
}
.contactModal .MuiPaper-root.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-rounded {
  min-height: 500px;
  border-top-left-radius: 500px;
  min-width: 500px;
  border: 2px solid #333;
}
.contactModal .MuiPaper-root form {
  width: 360px;
  margin-left: auto;
  margin-right: 15px;
  padding-top: 0;
}
.contactModal .MuiDialogActions-root.MuiDialogActions-spacing {
  display: inline-block;
  float: right;
  margin-top: .1em;
}
.contactModal form input[type="submit"]{
  background-color: #333;
  color: #dfdfdf !important;
  padding: 7px 35px 10px;
  border: 0;
  border-radius: 4px;
  font-size: 0.875rem;
  min-width: 64px;
  text-transform: uppercase;
  letter-spacing: 0.02857em;
  font-weight: 600;
  float: right;
  margin-top: 10px;
}
 
.contactModal form textarea {
  min-height: 135px;
  max-height: 135px;
  overflow-y: scroll;
}
.contactModal .MuiDialogContentText-root {
  text-align: right;
}
.loginAccess .input-field input {
  margin-left: 3rem;
}
.loginAccess .input-field label {
  margin-left: 6rem !important;
}
.loginAccess .input-field i {
  position: absolute;
  top: .9rem;
  left: 3.5rem;
  width: 3rem;
  font-size: 1.3rem;
}
i.fas.fa-envelope {
  font-size: 1.7rem;
}
 
.textTitleMarker{
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  font-weight:700;
  text-align: center;
  margin-top: 0;
  margin-bottom: 10px;
}
 
 
.textPlaceMarker{
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
  font-weight: bolder;
  text-align: center;
  margin-top: 0;
  margin-bottom: 10px;
}
 
.textLinkMarker{
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
  font-weight: bolder;
  text-align: center;
  margin-top: 0;
}
 
.textLinkMarker:hover{
  text-decoration: underline;
}

/*NEW*/
nav .nav-wrapper {
  padding-top: 1rem;
}
.userBody .nav-wrapper .right li i {
  display: inline-block;
  margin-right: 0.5rem;
  vertical-align: sub;
}
.interNav {
  margin-top: 0.9rem;
}
.container.container_tabs .tabs.center {
  margin: 50px 0;
  background-color: transparent;
}

.tabs .tab a:hover, .tabs .tab a.active {
  color: #fff;
  background-color: rgb(0 0 0 / 30%);
}
.tabs .tab a {
  color: #ccc;
  padding: 0 5px;
  background-color: transparent;
}
.tabs .indicator {
  background-color: #fff;
}
.box_download_album {
  background: #212121;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  position: relative;
  height: 3rem;
}
a.download_album {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 11px;
  color: #fff;
  font-size: smaller;
}
a.download_album:hover {
  color: #ccc;
  background: #2e2d2d;
}
.download_album i {
  vertical-align: bottom;
  margin-right: 10px;
}
.collection .collection-item.avatar {
  min-height: 63px;
}
.collection a.collection-item {
  color: #9e9e9e;
}

element.style {
}
.collection .collection-item.avatar {
    min-height: 63px;
    padding-left: 72px;
    position: relative;
}
.collection a.collection-item {
    display: block;
    -webkit-transition: .25s;
    transition: .25s;
    color: #9e9e9e;
}
.collection .collection-item {
    background-color: #eaeaea;
    padding: 20px 20px;
    border-bottom: 1px solid #9e9e9e;
}
.collection .collection-item.avatar:not(.circle-clipper)>.circle {
  top: 10px;
}
.tabs_proyects .tab {
  width: 250px;
}
.container_tabs iframe {
  width: 100%;
  margin-top: 1rem;
}

.tabs.center.tabs_proyects {
  overflow-x: hidden;
  overflow-y: hidden;
}

/*admin*/
.btn.btn_delete_admin {
  margin: 1.6rem 0 0;
  background: #035278;
}
.btn.btn_delete_admin:hover {
  opacity: 0.5;
}
.box_edits_inputs_admin {
  margin: 2rem 0 0;
}
.input_edit_admin {
  margin: 1rem 1rem 0 0 !important;
}
.add_item {
  margin: 2rem 0 1rem 0;
  display: block;
}
.add_item .btn-floating.btn-large {
  margin: 0 1rem 0 0;
}
.title_edit_admin {
  font-weight: 600;
}
.file-field.input-field.box_change_downloader {
  margin: 2rem 0 0;
}
.box_change_downloader .btn-login {
  margin-bottom: 1rem;
}
.add-icon.admin_edit_pilot .div_delete {
  position: absolute;
  bottom: 3.3rem;
  right: 1.7rem;
}
.add-icon.admin_edit_pilot .div_delete .modal_trigger.delete_class {
  color: #fff;
  padding: 0px 60px;
  margin-top: 15px;
  text-decoration: none;
  background-color: #035278;
  text-align: center;
  letter-spacing: .5px;
  -webkit-transition: background-color .2s ease-out;
  transition: background-color .2s ease-out;
  cursor: pointer;
  border: none;
  border-radius: 2px;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  text-transform: uppercase;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  font-size: 14px;
  outline: 0;
}