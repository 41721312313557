@media (max-width: 2560px){
    /* .userBody nav .brand-logo {
         top: 18px;
         width: 10%;
     }*/
     .userBody .nav-wrapper .right .color_links {
         font-size: 1.5rem;
     }
     .userBody .nav-wrapper .right li i {
         font-size: 29px;
     }
     .userBody .nav-wrapper .right li {
         margin-right: 20px;
     }
     /*.userBody .nav-wrapper .right {
         margin-right: -400px;
     }*/
     img.proyectImg {
         width: 100%;
         height: 350px;
     }
     .boxTextImgPage {
         /* left: 40%; */
     }
     .adminBody img.brand-logo.log_nav {
         width: 4%;
         top: 0px;
     }
     .adminBody nav.nav-wrapper .right {
         margin-right: -400px;
     }
     .contactModal .MuiDialog-container.MuiDialog-scrollPaper {
         right: -30px;
     }
     
 }
 @media (max-width: 2000px){
     .userBody .nav-wrapper .right .color_links {
         font-size: 1rem;
     }
     .userBody .nav-wrapper .right li i {
         font-size: 18px;
     }
     /*.userBody nav .brand-logo {
         width: 10%;
         top: 26px;
     }*/
     img.proyectImg {
         width: 100%;
         height: 300px;
     }
    /* .userBody .nav-wrapper .right {
         margin-right: -280px;
     }*/
     .adminBody img.brand-logo.log_nav {
         width: 5%;
         top: 25px;
     }
     .adminBody nav.nav-wrapper .right {
         margin-right: -280px;
     }
 }
 @media (max-width: 1700px){
    /* .userBody nav .brand-logo {
         top: 35px;
     }*/
     img.proyectImg {
         height: 320px;
     }
    /* .userBody .nav-wrapper .right {
         margin-right: -200px;
     }*/
     .boxTextImgPage {
         /* left: 33%; */
     }
     .adminBody img.brand-logo.log_nav {
         width: 6%;
         top: 30px;
     }
     .adminBody nav.nav-wrapper .right {
         margin-right: -150px;
     }
  
 }
 @media (max-width: 1440px){
    /* .userBody nav .brand-logo {
         top: 33px;
         width: 12%;
     }*/
     img.proyectImg {
         height: 260px;
     }
     .boxTextImgPage {
         /* left: 28%; */
     }
     .MuiPickersToolbar-toolbarLandscape {
         max-width: 198px !important;
     }
    /* .userBody .nav-wrapper .right {
         margin-right: -150px;
     }*/
     .userBody .nav-wrapper .right .color_links {
         font-size: 1rem;
     }
     .userBody .nav-wrapper .right li i {
         font-size: 20px;
     }
     .adminBody img.brand-logo.log_nav {
         top: 30px;
     }
 }
 @media (max-width: 1200){
    /* .userBody nav .brand-logo {
         top: 34px;
         width: 14%;
     }
     .userBody nav .brand-logo {
         top: 23px;
         width: 18%;
     }*/
     .boxTextImgPage {
         /* left: 26%; */
     }
     img.proyectImg {
         height: 190px;
     }
     .adminBody img.brand-logo.log_nav {
         top: 17px;
     }
     .adminBody nav.nav-wrapper .right {
         margin-right: -100px;
     }
 }
 @media (max-width: 1024px){
     /*admin*/
     .adminBody img.brand-logo.log_nav {
         top: 32px;
         width: 10%;
         left: 1.5em;
     }
     /*client*/
     .userBody {
         min-height: 100vh;
     }
    /* .userBody .nav-wrapper .right {
         margin-right: -120px;
     }*/
    /* .userBody nav .brand-logo {
         top: 30px;
         width: 20%;
         left: 1.5em;
     }*/
     img.proyectImg {
         height: 180px;
     }
     .boxTextImgPage {
         /* left: 21%; */
     }
     .MuiPickersToolbar-toolbarLandscape {
         max-width: 198px !important;
     }
  
     
 }
 @media (max-width: 991px){
     /*admin*/
     .adminBody nav.nav-wrapper .right {
         margin-right: -50px;
     }
     /*client*/
     img.proyectImg {
         height: 160px;
     }
    /* .userBody .nav-wrapper .right {
         margin-right: -60px;
     }*/
     .add-icon.admin_edit_pilot .div_delete .modal_trigger.delete_class {
        width: 100%;
    }
    .add-icon.admin_edit_pilot .div_delete {
        bottom: 6px;
        right: 1.9rem;
        width: 91%;
        left: 1.8rem;
    }
    .input-field.save_edit_admin .btn.btn-login {
        width: 100%;
        margin-bottom: 2rem;
    }
 }
 @media (max-width: 768px){
     /*admin*/
     .adminBody nav .brand-logo {
         left: 10% !important;
     }
     .adminBody img.brand-logo.log_nav {
         top: 19px;
     }
     .ReactModal__Content {
         left: 20px !important;
         right: 20px !important;
         bottom: auto !important;
     }
     img.proyectImg {
         height: 130px;
     }
  
     /*cliente*/
    /* .userBody nav .brand-logo {
         top: 40px;
         width: 20%;
         left: 105px !important;
     }*/
    /* .userBody .nav-wrapper .right {
         margin-right: -50px;
     }*/
     .userBody .nav-wrapper .right li {
         margin-right: 10px;
     }
     .boxTextImgPage {
         /* left: 14%; */
     }
     .MuiPickersModal-dialogRoot {
         min-width: 650px !important;
     }
     .capaBoxImgClient {
         width: 100%;
         height: 95%;
         background-color: #888;
         opacity: 0.5;
         position: absolute;
         top: 0;
         left: 0;
         display: block;
     }
     .textImgClient {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
         /* position: absolute;
         top: 22%;
         left: 9px; */
         color: #FFFFFF;
         text-align: center;
         width: 90%;
         background-color: #888;
         padding: 11px 0;
         opacity: 0.9;
         display: block;
     }
     .textImgClient h5 {
         font-size: 15px;
     }
     
 }
 @media (max-width: 767px){
     /*admin*/
     .adminBody nav.nav-wrapper .right {
         margin-right: -30px;
     }
     .page-footer {
         padding: 0 10px;
         text-align: center;
     }
     .MuiPickersModal-dialogRoot {
         min-width: 270px !important;
     }
     .contactModal .MuiPaper-root.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-rounded {
         min-height: 360px;
         border-top-left-radius: 360px;
         min-width: 360px;
     }
     .contactModal .MuiDialogContent-root {
         max-width: 270px;
     }
     .contactModal .MuiDialogContentText-root {
         margin-bottom: 12px;
         text-align: right;
     }
     .contactModal form textarea {
         min-height: 75px;
         max-height: 75px;
         overflow-y: scroll;
     }
     .contactModal .MuiPaper-root form {
         width: 280px;
     }
  
 }
 @media (max-width:480px){
     .adminBody nav.nav-wrapper .right {
         margin-right: -10px;
     }
     .adminBody img.brand-logo.log_nav {
         top: 30px;
         width: 20%;
         left: 16% !important;
     }
     input#name {
         padding-left: 95px;
         width: 70%;
     }
     input#uid {
         padding-left: 95px;
         width: 70%;
     }
     /*client*/
     .userBody .nav-wrapper .right {
         margin-right: 0;
         margin-top: 15px;
     }
   /*  .userBody nav .brand-logo {
         top: 30px;
         width: 24%;
         left: 50% !important;
     }*/
     /*.userBody nav {
         height: 145px !important;
     }*/
     .titleBody {
         margin-top: 40px;
     }
     .textTitleBox h4 {
         font-size: 17px;
     }
     img.proyectImg {
         height: 180px;
         margin-bottom: 15px;
     }
     .boxTextImgPage {
         /* top: 26%; */
         width: 280px;
         height: 110px;
         padding: 30px 0;
     }
     .boxTextImgPage h3 {
         font-size: 26px;
     }
  
     .ImgPage {
         height: 230px;
     }
     .firstSlide .imagenCarousel {
  
         height: 210px;
     }
     .boxSectionBody {
         padding: 0 20px;
     }
     .capaBoxImgClient {
         height: 90%;
     }
     .textImgClient {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
         /* top: 29%;
         left: 15px; */
     }
     .MuiPickersModal-dialogRoot {
         min-width: 270px !important;
     }
     .boxDatePicker {
         margin: 30px 0 50px;
     }
     .boxSectionBody iframe {
         height: 300px !important;
         padding: 20px;
     }
     .react-player iframe {
         padding: 0;
     }
     .loginAccess .input-field input {
         margin-left: 1rem;
     }
     a.download_album {
        top: -2px;
        left: -15px;
    }
    .add-icon.admin_edit_pilot .div_delete {
        width: 87%;
    }
     
 }
 @media (max-width: 411px){
     /*login*/
     .input-field.icon_user input,
     .input-field.icon_pass input {
         margin-left: 1.5rem;
     }
     .loginAccess .input-field .material-icons.prefix {
         left: 2.5rem;
     }
     .input-field .prefix ~ label.active {
         margin-left: 2.5rem !important;
     }
     /*client*/
     .userBody .nav-wrapper .right .color_links.active {
         padding: 0;
     }
     .userBody .nav-wrapper .right .color_links {
         padding: 0;
     }
     .textTitleBox h4 {
         font-size: 16px;
     }
     .boxSectionBody .pnlm-container {
         height: 300px !important;
     }
     .boxSectionBody iframe {
         height: 300px !important;
         padding: 20px;
     }
     .react-player iframe {
         padding: 0;
     }
     .loginAccess .input-field label {
         margin-left: 2rem !important;
     }
     .loginAccess .input-field i {
         left: 2.5rem;
     }
     .loginAccess .input-field input {
         margin-left: 3rem;
     }
  
 }
 @media (max-width: 380px){
    .textTitleBox h4 {
        font-size: 12px;
    }
    .add-icon.admin_edit_pilot .div_delete {
        width: 85%;
    }
 }
 @media (max-width: 330px){
     /*login*/
     .input-field.icon_user input,
     .input-field.icon_pass input {
         margin-left: .5rem;
     }
     .loginAccess .input-field .material-icons.prefix {
         left: 1rem;
     }
     .input-field .prefix ~ label.active {
         margin-left: 1rem !important;
     }
     .input-field .prefix ~ label {
         margin-left: 3.5rem !important;
     }
     .adminBody img.brand-logo.log_nav {
         width: 19%;
     }
     .white h5 {
         font-size: 1rem;
     }
     .adminBody .btn{
         font-size: 12px;
     }
     .logo_client {
         width: 12rem;
     }
     input#name {
         padding-left: 95px;
         width: 61%;
     }
     input#uid {
         padding-left: 95px;
         width: 61%;
     }
  
     /*client*/
    /* .userBody nav .brand-logo {
         width: 35%;
     }*/
     .userBody .nav-wrapper .right .color_links {
         padding: 0 4px 0 0;
         font-size: 0.8rem;
     }
     .userBody .nav-wrapper .right .color_links.active {
         padding: 0 4px 0 0;
         font-size: 0.8rem;
     }
     .userBody .nav-wrapper .right .optionOff {
         margin-right: 0;
     }
     .textTitleBox h4 {
         font-size: 12px;
     }
     img.proyectImg {
         height: 150px;
         margin-bottom: 15px;
     }
     .btn.btnClient {
        padding: 0 25px;
     }
     .capaBoxImgClient {
         height: 88%;
     }
     .boxTextImgPage {
         width: 230px;
         height: 117px;;
     }
     .boxTextImgPage h3 {
         font-size: 2rem;
     }
     .contactModal .MuiPaper-root.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-rounded {
         min-width: 310px;
     }
     .contactModal .MuiPaper-root form {
         width: 260px;
     }
     .contactModal form input[type="submit"] {
         padding: 7px 27px 10px;
     }
     .contactModal button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary {
         padding: 5px 27px;
     }
     .loginAccess .input-field i {
         left: 1.5rem;
     }
     .loginAccess .input-field label {
         margin-left: 1rem !important;
     }
     nav .brand-logo {
        left: 55% !important;
    }
    a.download_album {
        left: -23px;
    }
    .add-icon.admin_edit_pilot .div_delete {
        width: 83%;
    }
 }
 
 nav .sidenav-trigger {
    float: left;
    margin: 0 18px 0 40px;
}
.sidenav-trigger .material-icons {
    color: #333;
    font-size: 35px;
    height: 70px;
    line-height: 70px;
}
ul#mobile-nav {
    background: #212121;
    padding: 2rem;
}
#mobile-nav .interNav {
    margin: 0;
}
#mobile-nav .interNav .color_links {
    display: block;
    padding: 0.5rem;
    color: #ffffff;
    font-size: 16px;
}
#mobile-nav .interNav .color_links i {
    vertical-align: sub;
    margin-right: 10px;
}
#mobile-nav .interNav .color_links i.medium {
    font-size: 24px;
}